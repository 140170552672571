import { observer } from 'mobx-react'
import { useContext } from 'react'

import BaseOfferCard from '@/components/redesign/offers/BaseOfferCard'
import { Bookings } from '@/types/types'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

interface HighlightedOfferCardProps {
  offer: Bookings.HighlightedOffer
}
const HighlightedOfferCard = observer(({ offer }: HighlightedOfferCardProps) => {
  const { translateField } = useTranslation()
  const { appStore } = useContext(StoresContext)
  const { toggleSelectedHighlightedOffer } = appStore
  const offerName = translateField(offer.name_translations)

  return (
    <BaseOfferCard
      offer={offer}
      offerName={offerName}
      isAvailable
      onCardClick={() => {
        toggleSelectedHighlightedOffer(offer)
      }}
      showTooltip={false}
    />
  )
})

export default HighlightedOfferCard
